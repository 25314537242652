import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="pagination-posts" role="navigation">
      <div className="grid-x grid-margin-x">
        <div className="cell small-6 pagination-previous">
          <Link
            className={`button large expanded ${
              previousPagePath ? '' : 'disabled'
            } `}
            to={previousPagePath}
            rel="prev"
          >
            Previous
          </Link>
        </div>

        <div className="cell small-6 pagination-next">
          <Link
            className={`button large expanded ${
              nextPagePath ? '' : 'disabled'
            } `}
            to={nextPagePath}
            rel="next"
          >
            Next
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Pagination
